import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const TeamMember = ({ name, image, alt, role, email, phone, phoneNice }) => {
  return(
    <div className="md:w-2/3 mx-auto p-4 shadow-lg">
      {image &&
        <GatsbyImage image={image} alt={alt} />
      }
      <div className="">
        <h2>{name}</h2>
        <h3>{role}</h3>
        <p><a href={`mailto:${email}`}>{email}</a></p>
        <p><a href={`tel:${phone}`}>{phoneNice}</a></p>
      </div>
    </div>
  )
}

export default TeamMember