import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"
import TeamMember from "../components/teamMember"
import PageCTA from "../components/pageCTA"

export const query = graphql`
  query AboutUsPageQuery {
    banner: file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
const AboutUsPage = ({ data }) => {
  const banner = data.banner.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="About Us" />
      <Hero
        image={banner}
        header="About Bostonian Electric"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans space-y-8">
        <TeamMember
          name="Bostonian Electric & Control Solutions, Inc."
          phone="7814267380"
          phoneNice="(781) 426-7380"
        />
        <TeamMember
          name="Donald Wakeling"
          role="President"
          email="dwakeling@bostonianelectric.com"
          phone="7814267386"
          phoneNice="(781) 426-7386"
        />
        <TeamMember
          name="Eric Medrzycki"
          role="Vice President"
          email="emedrzycki@bostonianelectric.com"
          phone="7814267387"
          phoneNice="(781) 426-7387"
        />
      </section>
      <PageCTA
        h2="Ready to get started?"
        buttonText="Get a quote"
        destination="/contact/"
      />
    </Layout>
  )
}
export default AboutUsPage
